import React from 'react'
import { H1, Paragraph1 } from '@styles/Global.styles'
import {  Grid } from 'semantic-ui-react'
import { CheckoutSelectionCardModel } from '@models/Checkout/CheckoutSelectionCard'
import theme from '@styles/theme'
import { navigate } from 'gatsby'
import { BasketResponseModel } from '@models/Basket/BasketResponseModel'
import { Checkout } from '@models/Checkout/Checkout'
import { GiftingType } from './GiftingType'
import GiftingCheckoutSelectionCard from './GiftingCheckoutSelectionCard'
import { CheckoutGiftingCardModel } from './mapper'

export type GiftingOptionsSelectionProps = {
    heading: string
    subHeading: string
    body: string
    eCard: CheckoutSelectionCardModel
    basket: BasketResponseModel
    checkout: Checkout
    apiUrl: string
    eCardTemplate: CheckoutGiftingCardModel
    updateGiftingType: (giftingType: number) => void
    updatePersonalDetails: (personalDetails: Checkout) => void
}

interface GiftingOptionsSelectionState {
    giftingType: number
}

class MobileGiftingOptionsSelection extends React.Component<GiftingOptionsSelectionProps, GiftingOptionsSelectionState>{
    constructor(props: GiftingOptionsSelectionProps) {
        super(props);
        this.state = {giftingType : this.props.checkout.giftingType}
    }

    selectEcardOption = (giftingType: number) => {
        // this.setState({giftingType: 0})
        // if (this.state.giftingType == giftingType){
        //     this.props.updateGiftingType(0);            
        // } else{
        //     this.props.updateGiftingType(giftingType);
        //     this.setState({giftingType: giftingType})
        // }        
    }

    goBack = () => {
        let isRedesign = false;
        this.props.basket.basketLines.forEach((lineItem) => {

            if (lineItem.transactionTypeId === 2 || lineItem.transactionTypeId === 3) {
                isRedesign = true;
            }
        });

        if(this.props.checkout.isDealerOrder) {
            navigate('/select-a-style');
        }
        else if (isRedesign) {
            navigate('/shopping-cart');
        }
        else {
            navigate('/plate-owner-selection')
        }
    }

    continue = () => {       
        navigate('/personal-details');
    }

    checkFormValidation = () => {
        return true;
    }

    render() {

        const { heading, subHeading, body, eCard, checkout } = this.props;

        var MobileECard:CheckoutSelectionCardModel = {
            body: eCard.body,
            content: eCard.content,
            heading: eCard.heading,
            items: eCard.items
        };

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <H1>
                            {heading}
                        </H1>
                        <Paragraph1 color={theme.mobileTheme.color}>
                            <p>{subHeading}</p>
                        </Paragraph1>

                        <Grid stretched>                            
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <GiftingCheckoutSelectionCard hideButton={true} card={MobileECard} onSelect={() =>this.selectEcardOption(GiftingType.ECard.id)} 
                                ecardSelection={true} isSelected={checkout.giftingType == GiftingType.ECard.id} 
                                apiUrl={this.props.apiUrl} updatePersonalDetails={this.props.updatePersonalDetails} checkout={this.props.checkout} eCardTemplate={this.props.eCardTemplate}/>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>                
            </Grid>
        );
    }
}
export default MobileGiftingOptionsSelection;