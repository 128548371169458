import React from 'react'
import {
    CheckoutSelectionCardContainer,
    CheckoutSelectionCardIndicator,
    CheckoutSelectionCardHeading,
    CheckoutSelectionCardButton } from '@components/CheckoutSelectionCard/CheckoutSelectionCard.styles'
import theme from '@styles/theme'
import { Icon } from 'semantic-ui-react'
import { CheckoutSelectionCardModel } from '@models/Checkout/CheckoutSelectionCard'
import { GiftingOptionsSelectionCardButton } from './GiftingCheckoutSelectionCard.styles'
import { Checkout } from '@models/Checkout/Checkout'
import { CheckoutGiftingCardModel } from './mapper'
import EcardContainerMobile from './EcardContainerMobile'
import { EcardImg } from './EcardContainer.style'

const ecardImg = require('@assets/images/ecard.png')
export type GiftingCheckoutSelectionCardProps = {
    card: CheckoutSelectionCardModel
    onSelect: Function
    isSelected: boolean
    ecardSelection?: boolean
    expressCheckout?: boolean
    hideButton?: boolean
    apiUrl: string
    updatePersonalDetails: (personalDetails: Checkout) => void
    checkout: Checkout
    eCardTemplate: CheckoutGiftingCardModel
}

class GiftingCheckoutSelectionCard extends React.Component<GiftingCheckoutSelectionCardProps>{
    render() {

        const { heading, body, content, items } = this.props.card;
        const { isSelected, ecardSelection, expressCheckout, hideButton = false } = this.props;

        let isSelectedClass = isSelected;
        if(ecardSelection && ecardSelection == true){
            isSelectedClass = false
        }

        return (
            <>
                <CheckoutSelectionCardContainer /* className={isSelectedClass ? 'selected' : isSelected ? 'selectedMan' : ''} */ onClick={() => hideButton && this.props.onSelect()}>
                    <div>
                        <CheckoutSelectionCardHeading><EcardImg src={ecardImg} alt="Kiwiplates - E-Cart" />{heading}</CheckoutSelectionCardHeading>
                        {body && <span style={{color: theme.brand.colors.darkGrey}}>{body}</span>}
                        
                    </div>
                    <div>
                        {content && <span style={{color: '#3DAE2B'}} dangerouslySetInnerHTML={{ __html: content }} />}
                        
                    </div>
                    {!hideButton &&
                    <div>
                        {
                            isSelected &&
                            <CheckoutSelectionCardButton id="csc-selected" buttonType='primary' color={theme.brand.colors.green}>SELECTED</CheckoutSelectionCardButton>
                        }
                        {
                            !isSelected &&
                            <CheckoutSelectionCardButton id="csc-select" buttonType='secondary' color={theme.brand.colors.black} onClick={this.props.onSelect}>SELECT</CheckoutSelectionCardButton>
                        }
                    </div>}
                    {/* {
                        isSelected &&
                        <CheckoutSelectionCardIndicator>
                            <Icon name='check' size='small' />
                        </CheckoutSelectionCardIndicator>
                    } */}
                </CheckoutSelectionCardContainer>
                {
                    isSelected &&
                    <EcardContainerMobile updatePersonalDetails={this.props.updatePersonalDetails} apiUrl={this.props.apiUrl} checkout={this.props.checkout} eCardTemplate={this.props.eCardTemplate}/>
                }
            </>
            
        );
    }
}



export default GiftingCheckoutSelectionCard